var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "views_test_oss_upload" }, [
    _c("input", {
      ref: "file",
      staticClass: "file",
      attrs: { type: "file" },
      on: {
        change: function ($event) {
          return _vm.fileChange()
        },
      },
    }),
    _c(
      "button",
      {
        on: {
          click: function ($event) {
            return _vm.cancelUpload()
          },
        },
      },
      [_vm._v("取消上传")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }