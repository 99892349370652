<template>
  <div
    class="views_test_oss_upload"
  >
    <input
      type="file" 
      class="file"
      ref="file"
      @change="fileChange();"
    >
    <button
      @click="cancelUpload();"
    >取消上传</button>
  </div>
</template>
<script>
export default {
  methods:{
    async fileChange(){
      const {file} = this.$refs;
      if(file.files[0]){
        const res = await this.$oss.upload({file:file.files[0], handleProgress: this.handleProgress})
        // console.log(res);
      }
    },

    handleProgress(val){
      // console.log(Math.floor(val*100))
    },
    cancelUpload(){
      this.$oss.cancelUpload();
    }
  }
}
</script>
<style lang="scss" scoped>
.views_test_oss_upload{
  padding: 20px;
}
</style>